import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useApi } from '@tenant/composables'

export function useTextTemplateLanguages() {
    const store = useStore()
    const textTemplateLanguages = computed(
        () => store.getters.textTemplateLanguages
    )

    const getData = () => {
        store.dispatch('fetchTextTemplateLanguages')
    }

    onMounted(() => {
        if (textTemplateLanguages.value && textTemplateLanguages.value.length) {
            return
        }

        getData()
    })

    return { textTemplateLanguages, getData }
}

export function useTextTemplateTypes() {
    const store = useStore()
    const textTemplateTypes = computed(() => store.getters.textTemplateTypes)

    const getData = () => {
        store.dispatch('fetchTextTemplateTypes')
    }

    onMounted(() => {
        if (textTemplateTypes.value && textTemplateTypes.value.length) {
            return
        }

        getData()
    })

    return { textTemplateTypes, getData }
}

export function useTextTemplate() {
    const { execute } = useApi('/api/default-text-templates', 'GET')
    const getDefaultTextTemplate = (type, module = null, line_item = null) => {
        return execute({
            queries: {
                type,
                module,
                line_item,
            },
        })
    }

    return { getDefaultTextTemplate }
}
